<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-top-transition"
    max-width="400"
  >
    <template>
      <v-card>
        <v-card-title class="headline d-flex justify-center">
          Berhasil
        </v-card-title>
        <v-card-text>
          <div class="pt-2 text-center">{{messageDialog}}</div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            depressed
            @click="dialog = false"
          >Ok</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: {
    messageDialog: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
